import React, { FC } from "react";

type IconProps = {
    color: String,
};

const FourD: FC<IconProps> = ({ color }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="1668" height="1080" viewBox="0 0 1668 1080" fill="none" style={{ width: "100%", height: "auto"}}>
            <path d="M584.469 455.564V0H729.43V1081H584.469V602.271H0V447.843C92.5281 423.134 161.924 341.287 161.924 49.4171V0H308.427V49.4171C308.427 231.643 280.669 367.54 223.61 455.564H584.469Z" 
                fill={`${color}`} 
            
            />
            <path d="M883.053 1.54427H1299.43C1502.99 1.54427 1668 165.239 1668 369.084V713.46C1668 915.761 1516.87 1081 1314.85 1081H883.053V1.54427ZM1523.04 713.46V369.084C1523.04 234.731 1444.39 145.163 1307.14 145.163H1028.01V935.837H1299.43C1439.76 935.837 1523.04 849.357 1523.04 713.46Z" 
                fill={`${color}`}
                />
        </svg>
    );
}

export default FourD;
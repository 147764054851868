import { FC } from "react";
import styled from "styled-components";
import { Row } from '../../Global';
import Seta from '../../../../public/images/svg/Seta';
import { CSSObject } from "styled-components";

interface ContainerBaseProps {
  colorSeta?: string;
  colorText?: string;
  idSection: string;
  readonly sx?: CSSObject;
};

interface BaseText {
  readonly corText?: string;
}

const DetailFluid = styled.div`
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`

const TextScroll = styled.p<{ color?: string }>`
  font-family: SK Concretica;
  position: relative;
  margin-bottom: -25px;
  z-index: 1;
  font-size: 3.6rem;
  line-height: 100%;
  color: ${props => props.color};
`

const Scroll: FC<ContainerBaseProps> = ({ colorText, colorSeta, idSection , sx }) => {
  const scrollToTarget = (idSection: string) => {
    const target = document.getElementById(idSection);
    if ( target ){
      target.scrollIntoView({ behavior: 'smooth' });
    }
  }

  return (
    <Row
      alignItems="flex-end"
      justifyContent="flex-end"
      sx={sx}
      onClick={() => scrollToTarget(idSection)}
    >
      <TextScroll color={colorText}>scroll</TextScroll>
      <Seta color={`${colorSeta}`}></Seta>
    </Row>
  );
};

export default Scroll;


import React, { FC } from "react";
import styled, { css } from "styled-components";

type IconProps = {
    color: String,
    width: String,
    height: String,
    rotate?: Number,
};

interface ContainerBaseProps {
    readonly sx?: Partial<React.CSSProperties>;
}

const Circle: FC<IconProps> = ({ color, width, height, rotate }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
            style={
                rotate ? (
                    {
                        width: width,
                        height: height,
                        transform: `rotate(${rotate}deg)`,
                    }
                ) :
                    (
                        {
                            width: width,
                            height: height,
                        }
                    )
            }
            viewBox="0 0 253 250" fill="none" >
            <path fillRule="evenodd" clipRule="evenodd" d="M210.251 15.879C159.283 25.7785 112.228 50.7347 75.3271 87.7946C32.5551 130.751 6.1031 187.279 0.527751 247.641L16.4599 249.112C21.6928 192.458 46.5202 139.402 86.6651 99.084C120.737 64.865 164.033 41.6404 210.976 32.0492L211.647 47.0396L252.26 19.1386L209.562 0.495949L210.251 15.879Z" fill={color} />
        </svg>
    );
}

export default Circle;
import styled from "styled-components";
import { regraDeTres } from "@/utils";

interface ContainerBaseProps {
  readonly sx?: object;
}


const SectionBase = styled.div<ContainerBaseProps>(
  ({ sx }) => ({
    position: "relative",
    flex: "0 0 auto",
    width: "100%",
    height: "100%",
    background: "#D9D9D9",
    ...sx
  })
);

const GridGreen = styled.div`
    position: absolute;
    top: 0;
    left: 318px;
    height: 100%;
    width: 255px;
    background: #3EFF2F;
    display: none;
    @media (min-width: 1170px) {
      display: block;
      left:  ${regraDeTres(318, 1920)}%; ;
      width: ${regraDeTres(255, 1920)}%; 
    }
`

const H2 = styled.h2`
  font-family: "SK Concretica";
  font-size: 2.4rem;
  color: #000000;
  margin: 0 auto;
  text-align: left;
  width: 100%;
  line-height: 120%;
  font-weight: 400;

  @media (min-width: 1170px) {
    padding-left: ${regraDeTres(100, 1388)}%;
    max-width: ${regraDeTres(1058, 1388)}%;
    font-size: 4.2rem;
  }

  @media (min-width: 1600px){
    font-size: 5rem;
    margin-top: -100px;
  }

  @media (min-width: 1800px){
    font-size: 6rem;
    margin-top: -100px;
  }
`
const H3 = styled.h3`
  font-family: "SK Concretica";
  color: #000000;
  line-height: 100%;
  font-weight: 400;
  font-size: 4.8rem;

  @media (min-width: 1170px) {
    font-size: 7rem;
  }

  @media (min-width: 1600px){
    font-size: 9.6rem;
  }
`

const Paragrafo = styled.p`
  font-size: 1.6rem;
  line-height: 130%;
  letter-spacing: 0.32px;
  color: #565656;
  text-align: left;

  @media (min-width: 1170px) {
    font-size: 2.4rem;
  }

  @media (min-width: 1600px){
    font-size: 3.2rem;
  }
`

export { SectionBase, GridGreen, H2, H3, Paragrafo};
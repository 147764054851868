import react, { FC, useState } from "react";
import { Container, Row } from '../../Global/';
import { regraDeTres } from "../../../utils";
import Image from "next/image";
import { useRouter } from "next/router";

import  { ImageHover, Title, Time, BlockText, Text, BackDetail, MainTitle, StyleArticle } from "./style";

type BannerProps = {
    children?: React.ReactNode;
    data: {};
};

interface ContainerBaseProps {
    readonly sx?: CSSObject;
}

const News: FC<BannerProps> = ({data}: any) => {
  if ( !data ) return null;

  const router = useRouter();
  const S3_ROOT_PATH = "https://idk-site-strapi-media.s3.sa-east-1.amazonaws.com";

  const calculateReadTime = (content: string) => {
    const wordsPerMinute = 200;

    let textLength = content.split(" ").length;
    if ( textLength > 0 ) {
      let value = Math.ceil(textLength / wordsPerMinute)
      return Number(value) + " minuto(s)";
    }

    return null;
  }

  const getFirstCategoryName = (categories: any) => {
    if ( categories.data[0]?.attributes.Name ) {
      return categories.data[0].attributes.Name;
    }
    return "Sem categoria";
  }

  const getPostImage = (image: any) => {
    if ( image.data.attributes.formats.medium ) {
      const { hash, ext } = image.data.attributes.formats.medium;
      return `${S3_ROOT_PATH}/${hash}${ext}`;
    }

    return "";
  }

  const handleClick = (e: any, path: string) => {
    e.preventDefault();

    if ( !path ) return;
    router.push(`/blog/posts/${path}`);
  }

  return (
    <>
      <section id="id_news" style={{background: "#fff", position: "relative", zIndex: 1}}>
        <Row
          sx={{
            gap: "80px",
            padding: "80px 0px",
            background: "#EDEDED",
            flexDirection: "column",
            "@media(min-width: 1170px)": {
              gap: "42px",
              padding: "100px 0px 200px 0px",
            }
          }}
        >
          <Container
            sx={{
              width: "100%",
            }}
          >
            <MainTitle>
              <span>Blog</span>
              IDK
            </MainTitle>
            </Container>

            <Container
              sx={
                {
                  marginLeft: `${regraDeTres(16, 360)}%`,
                  marginRight: `${regraDeTres(16, 360)}%`,
                  "@media(min-width: 1170px)": {
                    padddig: "0px",
                    marginTop: "-150px",
                    maxWidth: `${regraDeTres(1320, 1920)}%`,
                    marginLeft: `${regraDeTres(405, 1920)}%`,
                    marginRight: "0",
                  }
                }
              }
            >
              { 
                data.map(({attributes}: any, index: number) => (
                  <Row key={index} sx={StyleArticle} onClick={(e) => handleClick(e, attributes.slug)}>
                    <Container sx={{position: "relative", height: "100%", overflow: "hidden"}}>
                      <Container sx={{height: "100%"}}>
                        <Title>{getFirstCategoryName(attributes.categories)}</Title>
                        <Time>{calculateReadTime(attributes.Content)}</Time>
                      </Container>
                      <ImageHover>
                        <Image src={getPostImage(attributes.Image)} width={225} height={132} quality={100} alt={"Random generated image"}/>
                      </ImageHover>
                    </Container>
                    <Container sx={{position: "relative", height: "100%", overflow: "hidden", marginTop: 20, "@media(min-width: 767px)": {marginTop: 0}}}>
                      <BlockText style={{minHeight: "132px"}}>
                        <BackDetail></BackDetail>
                        <Text>{attributes.Title}</Text>
                        <Image src="/images/svg/iconNext.svg" alt="Ícone de seta" width={38} height={32} />
                      </BlockText>
                    </Container>
                  </Row>
                ))
              }
          </Container>
        </Row>
      </section >
    </>
  );
}

export default News;
import react, { FC } from "react";
import Image from "next/image";
import { regraDeTres } from "../../../utils";
import { MainTitle, Text, GridGreen, CostumersBlock } from "./style";
import { Container, Row, Column } from '../../Global';
import Scroll from "../Scroll";

type BannerProps = {
  children?: React.ReactNode;
  data: {};
};


const Costumers: FC<BannerProps> = (data) => {
  const logos = [
    { width: 160, height: 160, logo: data?.data[0].Media.data[0].attributes.url, alt: data?.data[0].Media.data[0].attributes.alternativeText, cliente: "EMS" },
    { width: 160, height: 160, logo: data?.data[1].Media.data[0].attributes.url, alt: data?.data[1].Media.data[0].attributes.alternativeText, cliente: "Cliente Embratel" },
    { width: 160, height: 160, logo: data?.data[2].Media.data[0].attributes.url, alt: data?.data[2].Media.data[0].attributes.alternativeText, cliente: "Cliente LSB" },
    { width: 160, height: 160, logo: data?.data[3].Media.data[0].attributes.url, alt: data?.data[3].Media.data[0].attributes.alternativeText, cliente: "Cliente Raizen" },
    { width: 160, height: 160, logo: data?.data[4].Media.data[0].attributes.url, alt: data?.data[4].Media.data[0].attributes.alternativeText, cliente: "Cliente Celcoin" },
    { width: 160, height: 160, logo: data?.data[5].Media.data[0].attributes.url, alt: data?.data[5].Media.data[0].attributes.alternativeText, cliente: "Cliente MacDonalds" },
    { width: 160, height: 160, logo: data?.data[6].Media.data[0].attributes.url, alt: data?.data[6].Media.data[0].attributes.alternativeText, cliente: "Cliente Santander" },
    { width: 160, height: 160, logo: data?.data[7].Media.data[0].attributes.url, alt: data?.data[7].Media.data[0].attributes.alternativeText, cliente: "Cliente TIM" },
    { width: 160, height: 160, logo: data?.data[8].Media.data[0].attributes.url, alt: data?.data[8].Media.data[0].attributes.alternativeText, cliente: "Cliente Facebook" },
    { width: 160, height: 160, logo: data?.data[9].Media.data[0].attributes.url, alt: data?.data[9].Media.data[0].attributes.alternativeText, cliente: "Cliente Tirolezes" },
    { width: 160, height: 160, logo: data?.data[10].Media.data[0].attributes.url, alt: data?.data[10].Media.data[0].attributes.alternativeText, cliente: "Cliente Faber Castel" },
    { width: 160, height: 160, logo: data?.data[11].Media.data[0].attributes.url, alt: data?.data[11].Media.data[0].attributes.alternativeText, cliente: "Cliente Cebrace" },
    { width: 160, height: 160, logo: data?.data[12].Media.data[0].attributes.url, alt: data?.data[12].Media.data[0].attributes.alternativeText, cliente: "Cliente Banco do Brasil" },
    { width: 160, height: 160, logo: data?.data[13].Media.data[0].attributes.url, alt: data?.data[13].Media.data[0].attributes.alternativeText, cliente: "Cliente Refit" },
    { width: 160, height: 160, logo: data?.data[14].Media.data[0].attributes.url, alt: data?.data[14].Media.data[0].attributes.alternativeText, cliente: "Cliente Claro" },
    { width: 160, height: 160, logo: data?.data[15].Media.data[0].attributes.url, alt: data?.data[15].Media.data[0].attributes.alternativeText, cliente: "Cliente Nestle" },
    { width: 160, height: 160, logo: data?.data[16].Media.data[0].attributes.url, alt: data?.data[16].Media.data[0].attributes.alternativeText, cliente: "Cliente Ensen" },
    { width: 160, height: 160, logo: data?.data[17].Media.data[0].attributes.url, alt: data?.data[17].Media.data[0].attributes.alternativeText, cliente: "Cliente Itau" },
    { width: 160, height: 160, logo: data?.data[18].Media.data[0].attributes.url, alt: data?.data[18].Media.data[0].attributes.alternativeText, cliente: "Cliente Victor Hugo" },
    { width: 160, height: 160, logo: data?.data[19].Media.data[0].attributes.url, alt: data?.data[19].Media.data[0].attributes.alternativeText, cliente: "Cliente Helbor" },
    { width: 160, height: 160, logo: data?.data[20].Media.data[0].attributes.url, alt: data?.data[20].Media.data[0].attributes.alternativeText, cliente: "Cliente Doris" },
    { width: 160, height: 160, logo: data?.data[21].Media.data[0].attributes.url, alt: data?.data[21].Media.data[0].attributes.alternativeText, cliente: "Cliente TopTherm" },
  

  ];

  return (
    <>
      <section id="clients" style={{ position: "relative", zIndex: 2 }}>
        <Row

          sx={{
            padding: "60px 0px 80px 0px",
            flexDirection: "column",
            background: "#D9D9D9",
            position: "relative",
            "@media(min-width: 1170px)": {
              padding: "80px 0px 120px 0px",
            }
          }}
        >
          <Container
            sx={{
              display: "none",
              "@media(min-width: 1170px)": {
                display: "block",
              }
            }}
          >
            <GridGreen>
              <Scroll
                idSection="id_news"
                colorText="#000000"
                colorSeta="#FFFFFF"
                sx={{
                  position: "absolute",
                  bottom: 40,
                  left: 0,
                  zIndex: 3,
                  width: 212,
                  transform: "translate(-25%, -100%) rotate(-90deg)",
                  cursor: 'pointer',
                }}

              ></Scroll>
            </GridGreen>
          </Container>

          <Container
            sx={{
              width: 160,
              marginLeft: `${regraDeTres(117, 1920)}%`,
              "@media(min-width: 1170px)": {
                width: 246,
              }

            }}
          >
            <MainTitle>
              CL<span>I</span>
              EN
              TES
            </MainTitle>
          </Container>


          <CostumersBlock>
            <Row sx={{ justifyContent: 'center', alignSelf: "center", alignItems: 'center', width: "100%", "@media(min-width: 1170px)": { justifyContent: "space-between", width: "65%", marginLeft: "7%" }, "@media(min-width: 1500px)": { width: "58%", marginLeft: "5%" } }}>
              <Image src={`${logos[0].logo}`} height={logos[0].height} width={logos[0].width} alt={logos[0].alt}></Image>
              <Image src={`${logos[1].logo}`} height={logos[1].height} width={logos[1].width} alt={logos[1].alt}></Image>
              <Image src={`${logos[2].logo}`} height={logos[2].height} width={logos[2].width} alt={logos[2].alt}></Image>
              <Image src={`${logos[3].logo}`} height={logos[3].height} width={logos[3].width} alt={logos[3].alt}></Image>
            </Row>
            <Row sx={{ justifyContent: 'center', alignSelf: "center", alignItems: 'center', width: "100%", "@media(min-width: 1170px)": { justifyContent: "space-between", width: "65%", marginLeft: "7%" }, "@media(min-width: 1500px)": { width: "58%", marginLeft: "5%" } }}>
              <Image src={`${logos[5].logo}`} height={logos[5].height} width={logos[5].width} alt={logos[5].alt}></Image>
              <Image src={`${logos[7].logo}`} height={logos[7].height} width={logos[7].width} alt={logos[7].alt}></Image>
              <Image src={`${logos[9].logo}`} height={logos[9].height} width={logos[9].width} alt={logos[9].alt}></Image>
              <Image src={`${logos[10].logo}`} height={logos[10].height} width={logos[10].width} alt={logos[10].alt}></Image>
            </Row>
            <Row sx={{ justifyContent: 'center', alignSelf: "center", alignItems: 'center', width: "100%", "@media(min-width: 1170px)": { justifyContent: "space-between", width: "78%", marginLeft: "17%" }, "@media(min-width: 1500px)": { width: "75%", marginLeft: "23%" } }}>
              <Image src={`${logos[11].logo}`} height={logos[11].height} width={logos[11].width} alt={logos[11].alt}></Image>
              <Image src={`${logos[20].logo}`} height={logos[20].height} width={logos[20].width} alt={logos[20].alt}></Image>
              <Image src={`${logos[8].logo}`} height={logos[8].height} width={logos[8].width} alt={logos[8].alt}></Image>
              <Row sx={{ alignSelf: "center", alignItems: 'center' }}>
                <Container
                  sx={{
                    height: 80,
                    width: 80,
                    border: "2px dotted black",
                    margin: "40px 0",
                    "@media(min-width: 1170px)": {
                      height: 160,
                      width: 160,
                    },
                    "@media(min-width: 1500px)": {
                      margin: "0 50px"
                    }
                  }}
                >
                </Container>
                <Text>
                  Espaço
                  reservado
                  para você
                </Text>
              </Row>
            </Row>
            <Row sx={{ justifyContent: 'center', alignSelf: "center", alignItems: 'center', width: "100%", "@media(min-width: 1170px)": { justifyContent: "space-between", width: "65%", marginLeft: "7%" }, "@media(min-width: 1500px)": { width: "58%", marginLeft: "5%" } }}>
              <Image src={`${logos[15].logo}`} height={logos[15].height} width={logos[15].width} alt={logos[15].alt}></Image>
              <Image src={`${logos[17].logo}`} height={logos[17].height} width={logos[17].width} alt={logos[17].alt}></Image>
              <Image src={`${logos[18].logo}`} height={logos[18].height} width={logos[18].width} alt={logos[18].alt}></Image>
              <Image src={`${logos[19].logo}`} height={logos[19].height} width={logos[19].width} alt={logos[19].alt}></Image>
            </Row>
            <Row sx={{ justifyContent: 'center', alignSelf: "center", alignItems: 'center', width: "100%", "@media(min-width: 1170px)": { justifyContent: "space-between", width: "65%", marginLeft: "7%" }, "@media(min-width: 1500px)": { width: "58%", marginLeft: "5%" } }}>
              <Image src={`${logos[14].logo}`} height={logos[14].height} width={logos[14].width} alt={logos[14].alt}></Image>
              <Image src={`${logos[21].logo}`} height={logos[21].height} width={logos[21].width} alt={logos[21].alt}></Image>
              <Image src={`${logos[13].logo}`} height={logos[13].height} width={logos[13].width} alt={logos[13].alt}></Image>
            </Row>

            <Row sx={{ justifyContent: 'center', alignSelf: "center", alignItems: 'center', width: "100%", "@media(min-width: 1170px)": { justifyContent: "space-evenly", width: "56%", marginLeft: "-10%" }, "@media(min-width: 1500px)": { width: "50%", marginLeft: "-10%" } }}>
            </Row>
          </CostumersBlock>

        </Row >
      </section >
    </>

  );
}

export default Costumers; 
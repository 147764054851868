import styled, { CSSObject } from "styled-components";
import { regraDeTres } from "../../../utils";

interface ContainerBaseProps {
  readonly sx?: CSSObject;
}

interface BaseBackground {
  $position?: string;
  $url?: string;
}

interface AfterColor {
  $afterColor?: string;
}

const SectionBase = styled.div<ContainerBaseProps>(
  ({ sx }) => ({
    position: "initial",
    flex: "0 0 auto",
    width: "100%",
    background: "#959595",
    ...sx
  } as CSSObject)

  
);

const Slider = styled.div<ContainerBaseProps>(({ sx }) => (
  {
    position: "relative",
    minHeight: "100vh",
    background: "#959595",
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    ...sx
  }
));

const MainWrap = styled.div<any>(({sx}) => ({
  margin: "0 auto",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  width: "100%",
  "@media(min-width: 1170px)": {
    width: `${regraDeTres(1592, 1920)}%`,
    padding: "75px 0px"
  },
  "@media(min-width: 1600px)": {
    padding: "150px 0px"
  },
  ...sx
}))

const Wrapper = styled.div<ContainerBaseProps>(({ sx }) => ({
  position: "relative",
  flex: 1,
  zIndex: "",
  maxWidth: `${regraDeTres(288, 358)}%`,
  margin: `0px ${regraDeTres(16, 358)}% 0px ${regraDeTres(56, 358)}%`,

  "@media(min-width: 1170px)": {
    maxWidth: `${regraDeTres(460, 1592)}%`,
  },
  ...sx
}))

const Title = styled.h3`
    font-family: "SK Concretica";
    font-size: 9.6rem;
    line-height: 110%;
    color: #FFFFFF;
    font-weight: 400;
    font-size: 4.8rem;
    margin-bottom: 10px;

    @media(min-width: 1170px){
      font-size: 5rem;
    }
    @media(min-width: 1650px){
      font-size: 9.6rem;
    }

    

`

const Text = styled.p<AfterColor>(({ $afterColor }) => ({
  fontFamily: "'Inter', sans-serif",
  fontWeight: "400",
  letterSpacing: "0.32px",
  lineHeight: "130%",
  color: "#FFFFFF",
  position: "relative",
  zIndex: 1,
  fontSize: "1.6rem",

  '&:before': {
    content: '""',
    background: `${$afterColor}`,
    width: "28px",
    height: "calc(110% + 50px)",
    display: "block",
    position: "absolute",
    top: "calc(-5% - 50px)",
    left: "-40px",
    zIndex: -1,
  },

  '@media(min-width: 1170px)': {
    fontSize: "1.6rem",
    lineHeight: "140%",
    marginTop: "20px",

    '&:before': {
      width: "80px",
      height: "110%",
      top: "-5%",
      left: "-40px",
    },
  },

  '@media(min-width: 1650px)': {
    marginTop: "50px",
    fontSize: "2rem",
  },
}));

const Title2 = styled.h4`
  color: #FFF;
  text-align: center;
  font-family: SK Concretica;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  max-width: 300px;
  text-align: center;
  margin: 0 auto;

  @media(min-width: 1170px){
    font-size: 4rem;
  },

  @media(min-width: 1650px){
    font-size: 4rem;
  },

`

const BackgroundImagesCenter = styled.div<BaseBackground>(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  width: "100%",
  maxWidth: "100%",
  top: 0,
  left: 0,
  zIndex: 0,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  "@media(min-width: 1170px)": {
    position: "absolute",
  }

}) as CSSObject)

export { SectionBase, Slider, MainWrap, Wrapper, Title, Text, Title2, BackgroundImagesCenter };
import React from 'react';
import { motion } from 'framer-motion';

export const Layout: React.FC<{children?: React.ReactNode}> = ({children}) => (
  <motion.div
    initial={{y: 0, opacity: 0}}
    animate={{y: 0, opacity: 1}}
    exit={{y: 300, opacity: 0}}
    transition={{
      type: "spring",
      bounce: 0,
      stiffness: 210,
      damping: 20
    }}
  >
    {children}
  </motion.div>
)
import { useState, useEffect } from "react";
import styles from "./style.module.scss"
import MethodologiesData from "./types"

const Methodologies = (props: MethodologiesData) => {
  const [loading, setLoading] = useState(true);
  const [windowWidth, setWindowWidth] = useState(null);
  if (!props || !props.data || props.data.length === 0) return

  useEffect(() => {
    const updateWindowWidth = () => {
      setWindowWidth(window.innerWidth);
    };

    // Atualiza a largura da janela quando o componente monta
    updateWindowWidth();

    // Adiciona um ouvinte de evento de redimensionamento da janela
    window.addEventListener('resize', updateWindowWidth);

    // Remove o ouvinte de evento ao desmontar o componente
    return () => {
      window.removeEventListener('resize', updateWindowWidth);
    };
  }, []);

  useEffect(() => {
    // Verifica se a largura da janela é menor ou igual a 1170 pixels
    if (windowWidth && windowWidth <= 1170) {
      setLoading(false);
    }
  }, [windowWidth]);


  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 5000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <>
      <section id="methodology" className={styles.methodologies}>
        {
          props.data.map((methodology, index: number) =>
            <div
              key={methodology.Title}
              className={`${styles.methodology} methodology__inner`}
              data-index={index}
              style={{
                zIndex: index
              }}
            >
              <img
                className={styles.methodologyBackground}
                src={methodology.Background.data.attributes.url}
              // data-order={index % 2 ? "even" : "odd"}
              />

              <div className={styles.methodologyContent}>
                <img src={methodology.Media.data.attributes.url} alt={methodology.Media.data.attributes.alternativeText ?? "Imagem destaque"} />
              </div>
            </div>
          )
        }

        {/* <div
          className={`${styles.methodology} methodology__inner`}
          style={{
            zIndex: props.data.length + 1
          }}
        >
          4D
        </div> */}

        <svg id="methodologyArrows" className={styles.arrows} width="614" height="617" viewBox="0 0 614 617" fill="none">
          <path className="fullOpacity" fillRule="evenodd" clipRule="evenodd" d="M82.3974 352.47L51.3328 317.748L33.8563 360.936L50.5483 358.025C60.7144 408.514 85.6728 455.07 122.525 491.603C165.576 534.28 222.162 560.607 282.536 566.049L283.973 550.113C227.307 545.006 174.196 520.295 133.79 480.24C99.3 446.049 75.91 402.506 66.3161 355.275L82.3974 352.47Z" fill="#00FF00" style={{ position: "relative", zIndex: 11 }} />
          <path fillRule="evenodd" clipRule="evenodd" d="M256.25 50.8794C205.282 60.7789 158.227 85.7351 121.326 122.795C78.5541 165.751 52.1021 222.279 46.5268 282.641L62.459 284.113C67.6919 227.458 92.5192 174.402 132.664 134.084C166.736 99.8653 210.032 76.6408 256.975 67.0495L257.646 82.04L298.259 54.139L255.561 35.4963L256.25 50.8794Z" fill="#646464" style={{ position: "relative", zIndex: 9 }} />
          <path fillRule="evenodd" clipRule="evenodd" d="M490.349 122.626C447.538 79.7089 391.099 53.0662 330.757 47.287L329.231 63.2142C385.868 68.6383 438.84 93.6447 479.022 133.926C513.871 168.861 537.208 213.388 546.235 261.548L530.703 262.906L558.689 300.154L579.79 258.616L562.247 260.149C552.842 208.244 527.853 160.221 490.349 122.626Z" fill="#646464" style={{ position: "relative", zIndex: 9 }} />
          <path fillRule="evenodd" clipRule="evenodd" d="M348.393 547.384L345.258 532.2L308.452 560.766L354.678 577.828L351.629 563.06C404.493 553.64 453.333 528.07 491.287 489.632C533.879 446.497 560.095 389.859 565.418 329.474L549.479 328.069C544.484 384.745 519.878 437.905 479.902 478.39C444.177 514.571 398.174 538.605 348.393 547.384Z" fill="#646464" style={{ position: "relative", zIndex: 9 }} />
        </svg>
      </section>

      <div className={styles.floatingContent}>
        {
          props.data.map((methodology) =>
            <div key={methodology.Title} className={`${styles.floatingContentBlock} floatingContentBlock`}>
              <p data-attr="title">{methodology.Title}</p>
              <p data-attr="description" style={{ "--methodologyColor": methodology.Color }}>{methodology.Description}</p>
            </div>
          )
        }
      </div>

      <section className={styles.methodologyGroup}>
        <div className={styles.methodology}>
          <img className={styles.methodologyBackground} />

          <div className={styles.methodologyContent}>
            <div className={styles.content}>
              {
                props.data.map((methodology) =>
                  <div className={styles.methodologyGroupContent}>
                    <h2>{methodology.Title}</h2>
                    <p style={{ "--methodologyColorContent": methodology.Color }}><span>{methodology.Description}</span></p>
                  </div>
                )
              }

              <p className={styles.methodologyLogo}>4D</p>
              <p className={styles.designDriven}>Design Driven<br />Data Driven</p>
              <p className={styles.process}>O processo não para, <br /> as melhorias continuam sempre.</p>
            </div>
          </div>
        </div>

        <svg width="614" height="617" viewBox="0 0 614 617" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M348.392 547.384L345.258 532.2L308.452 560.766L354.678 577.829L351.629 563.06C404.493 553.64 453.333 528.07 491.287 489.632C533.879 446.498 560.095 389.859 565.417 329.474L549.479 328.069C544.483 384.745 519.878 437.905 479.902 478.39C444.176 514.571 398.174 538.605 348.392 547.384Z" fill="#F83FE5" />
          <path fill-rule="evenodd" clip-rule="evenodd" d="M256.25 50.8796C205.282 60.779 158.227 85.7352 121.326 122.795C78.5541 165.751 52.1021 222.279 46.5268 282.641L62.459 284.113C67.6919 227.458 92.5192 174.402 132.664 134.084C166.736 99.8655 210.032 76.6409 256.975 67.0497L257.646 82.0402L298.259 54.1391L255.561 35.4965L256.25 50.8796Z" fill="#FF7B00" />
          <path fill-rule="evenodd" clip-rule="evenodd" d="M82.3972 352.471L51.3326 317.748L33.8561 360.937L50.5484 358.025C60.7145 408.514 85.6729 455.07 122.525 491.603C165.576 534.28 222.162 560.607 282.536 566.049L283.973 550.113C227.307 545.006 174.196 520.296 133.79 480.24C99.3001 446.049 75.91 402.506 66.3161 355.275L82.3972 352.471Z" fill="#00FF00" />
          <path fill-rule="evenodd" clip-rule="evenodd" d="M490.349 122.626C447.538 79.709 391.1 53.0662 330.757 47.2871L329.231 63.2142C385.868 68.6384 438.84 93.6448 479.022 133.926C513.871 168.861 537.208 213.388 546.236 261.548L530.703 262.906L558.689 300.154L579.79 258.616L562.247 260.149C552.842 208.244 527.853 160.221 490.349 122.626Z" fill="#00D1FF" />
        </svg>
      </section>
    </>
  )
}

export default Methodologies
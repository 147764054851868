import styled from "styled-components";
import { regraDeTres } from "../../../utils";

const MainTitle = styled.h2`
    font-family: "SK Concretica";
    font-size: 6.4rem;
    line-height: 100%;
    color: #000000;
    font-weight: 400;
    letter-spacing: 0.03rem;


    span{
        color: #00FF00;
        display: inline-block;
        position: relative;

        &:before {
            display: block;
            position: absolute;
            top: 60px;
            right: 0px;
            z-index: 0;
            content: "";
            background: #39FF14;
            width: 400px;
            height: 32px;
            z-index: -1;
        }
    }

    @media(min-width: 1170px){
        font-size: 10rem;
    }

    @media(min-width: 1600px){
        font-size: 15rem;
    }
`

const Text = styled.h3`
  font-family: "SK Concretica";
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 100%;
  
  z-index: 1;
  width: 110px;
  left: 10px;
  position: relative;
  top: 5px;


  &:before {
    display: block;
    
    content: "";
    background: #39FF14;
    width: 100px;
    height: 15px;
    z-index: -1;
    left: -50px;
    top: 30px;
    position: relative;
  }

  &:after {
    flex: 0 0 auto;
    height: 80px;
    width: 80px;
    border: 2px dotted black;
  }


  @media(min-width: 1170px){
    font-size: 3.6rem;
    width: 170px;
    top: -30px;
    left: 20px;
    &:before {
      left: -105px;
      top: 40px;
      width: 170px;
      height: 30px;
    }
  }

  @media(min-width: 1600px){
    width: 320px;
    font-size: 7.2rem;
    top: 15px;
    left: -35px;

    &:before {
      top: 85px;
      left: -120px;
      width: 255px;
      height: 42px;
    }
  }
`

const GridGreen = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 300px;
  background: #3EFF2F;
  z-index: 0;
  
  @media (min-width: 1170px) {
    top: 0;
    right: 0;
    width: ${regraDeTres(262, 1920)}%;
    height: calc(100% + 13rem);
  }

  @media (min-width: 1600px) {
    top: 0;
    right: 0;
  }
`

const CostumersBlock = styled.div`
  overflow: auto;
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  align-items: center; 
  justify-content: center;

  img {
    filter: grayscale(100%);
    transition: filter 150ms ease;
    object-fit: cover;
    height: auto;
    max-width: 120px;
    margin: 30px 20px;
    &:hover {
      filter: grayscale(0);
    }
  }
  
  &::-webkit-scrollbar {
    width: 33px;               
  }
  
  &::-webkit-scrollbar-track {
    background: #3EFF2F;;       
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: #252525;         
  }
  
  @media(min-width: 1170px){
    margin-top: -100px;
    margin-left: 0;
    width: 100%;
    padding-right: 0;
    overflow: inherit;
    justify-content: center;
    
    img {
      margin: 40px 15px;
      min-width: 150px;
    }
  }
  
  @media(min-width: 1500px){
    img {
      margin: 40px 40px;
    }
  }
`

export { MainTitle, Text, GridGreen, CostumersBlock };
import styled from "styled-components"
import { regraDeTres } from "../../../utils";

const ImageHover = styled.div.attrs({ className: 'image__hover' })`
  display: none;
  width: 255px;
  height: 100%;
  position: absolute;
  top: 0px;
  transform: translate(100%, 0px);
  transition: 0.25s ease all;
  background: #D9D9D9;

  img{
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  @media(min-width: 1170px){
    display: block;
  }
`

const Title = styled.h2`
  font-family: "SK Concretica";
  font-size: 3.3rem;
  color: #000000;
  margin-bottom: 10px;
  margin-top: 25px;
`

const Time = styled.p`
  font-family: "SK Concretica";
  color: #A7A7A7;
  font-size: 2.4rem;
`

const BlockText = styled.div.attrs({ className: 'block__text' })`
  position: relative;
  padding: 7px 27px;

  img{
    display: none;
    position: absolute;
    right: 10px;
    bottom: 10px;
    width: 24px;
    height: auto;
  }

  @media(min-width: 767px) {
    padding: 32px 42px;

    img{
        width: 38px;
        height: 32px;
        right: 20px;
        bottom: 20px;
        
    }
  }
`

const Text = styled.div`
  font-family: "Inter", sans-serif;
  color: #000000;
  font-size: 2rem;
  line-height: 130%;
  position: relative;
  z-index: 1;

  @media(min-width: 1170px){
    font-size: 2.4rem;
    line-height: 3rem;
  }
  @media(min-width: 1600px){
    font-size: 2.8rem;
    line-height: 3.4rem;
  }
`

const BackDetail = styled.div.attrs({ className: 'background__hover' })`
  position: absolute;
  left: 0;
  top: 0;
  width: 12px;
  height: 100%;
  background: #929292;
  z-index: 0;
  transition: 0.25s ease width;
`

const MainTitle = styled.h2`
    font-family: "SK Concretica";
    font-size: 9.6rem;
    line-height: 100%;
    color: #00FF00;
    font-weight: 400;
    letter-spacing: 0.03rem;
    position: relative;
    z-index: 0;
    margin-left: ${regraDeTres(20, 360)}%;   

    span{
        color: #000000;
        font-size: 4.8rem;
        line-height: 100%;
        display: block;
        position: relative;
        width: 186px;

        &:before {
            display: block;
            position: absolute;
            top: 33px;
            right: 0px;
            z-index: 0;
            content: "";
            background: #39FF14;
            width: 400px;
            height: 32px;
            z-index: -1;
        }
    }

    @media(min-width: 1170px) {
        margin-left: ${regraDeTres(120, 1920)}%;
        font-size: 10rem;
        span{
            font-size: 8rem;
            &:before {
                top: 65px;
                width: 400px;
            }
        }
    }

    @media(min-width: 1600px) {
        font-size: 12.8rem;
        span{
            font-size: 7rem;
        }
    }

`

const StyleArticle = {
  minHeight: "132px",
  marginBottom: 40,
  display: "grid",
  width: "100%",
  cursor: "pointer",
  zIndex: 10,

  "&:last-child": {
    marginBottom: 0,
  },
  "&:hover": {
    transition: "0.3s ease all",

    ".image__hover": {
      transform: "translate(0%, 0px)",
    },

    ".background__hover": {
      width: "100%",
      background: "#00FF00",
    },
    ".block__text": {

      img: {
        display: "block"
      }

    }
  },
  "@media(min-width: 800px)": {
    gridTemplateColumns: "243px 1fr",
    marginBottom: 32,
  },
}

export { ImageHover, Title, Time, BlockText, Text, BackDetail, MainTitle, StyleArticle }
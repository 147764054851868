import { FC, useState, useRef, useEffect } from "react";
import Image from 'next/image';
import styled, { CSSObject } from "styled-components";
import { Container, ModalWithVideo } from "@/components/Global";
import { regraDeTres } from "@/utils";

type BannerProps = {
  children?: React.ReactNode;
  data: any;
} & ContainerBaseProps;

interface ContainerBaseProps {
  readonly sx?: CSSObject;
}

const BannerBase = styled.div<Rename<ContainerBaseProps, "sx", "$sx">>(
  ({ $sx }) => ({
    flex: "0 0 auto",
    width: "100%",
    display: "inherit",
    position: "relative",
    ...$sx
  } as CSSObject)
);

const Banner: FC<BannerProps> = ( { sx, data }) => {
  const [ startVideo, setStartVideo ] = useState<boolean>(false)
  const [ bannerImage, setBannerImage ] = useState<string | null>("");
  const primaryBannerImage = useRef<HTMLImageElement | null>(null);
  const secondaryBannerImage = useRef<HTMLImageElement | null>(null);

  const handleStartVideo = () => {
    setStartVideo(true);
  }

  const scrollToTarget = (idSection: string) => {
    const target = document.getElementById(idSection);
    if (target) {
      target.scrollIntoView({ behavior: 'smooth' });
    }
  }

  const handleBannerImageChange = (status: boolean) => {
    if ( !primaryBannerImage.current || !secondaryBannerImage.current ) return;

    if ( status ) {
      primaryBannerImage.current.style.display = "none";
      secondaryBannerImage.current.style.display = "block";
    } else {
      primaryBannerImage.current.style.display = "block";
      secondaryBannerImage.current.style.display = "none";
    }
  }

  useEffect(() => {
    if ( document.body.clientWidth > 1170 ) {
      setBannerImage(data?.Video_and_frames?.url)
    } else {
      setBannerImage(data?.Video_and_frames_mobile?.url)
    }
  }, [])

  return <BannerBase $sx={sx}>
    <ModalWithVideo
      startVideo={startVideo}
      parentVideoHandler={setStartVideo}
      videoUrl="https://s3.sa-east-1.amazonaws.com/idkmedia.co/videos/7d798d85-8486-4b56-ac0d-90b9a7b84db7.mp4"
    />

    <Container
      sx={{
        width: "100%",
        display: "inherit",
        height: "100vh",
        "img": {
          width: "100%",
          height: "auto",
          objectFit: "cover",
        }
      }}
    >
      <Image ref={primaryBannerImage} loading="eager" src={`${bannerImage ?? data?.Video_and_frames?.url}`} alt="Minha Imagem" width={1900} height={950} />
      <Image style={{display: "none"}} ref={secondaryBannerImage} loading="eager" src="https://idk-site-strapi-media.s3.sa-east-1.amazonaws.com/7d798d85_8486_4b56_ac0d_90b9a7b84db7_9fcc4cd8bb.webp" alt={"IDK"} width="1920" height={1080} />
    </Container>

    <Container
      sx={{
        cursor: "pointer",
        position: "absolute",
        minWidth: "100px",
        width: `${regraDeTres(200, 1920)}%`,
        height: "auto",
        display: "block",
        opacity: 0.5,
        transition: "all 150ms ease-in-out",
      }}
      onMouseOver={() => handleBannerImageChange(true)}
      onMouseLeave={() => handleBannerImageChange(false)}
      onClick={handleStartVideo}
    >
      <svg className="play-button" viewBox="0 0 214 214" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M107 209C163.333 209 209 163.333 209 107C209 50.667 163.333 5 107 5C50.667 5 5 50.667 5 107C5 163.333 50.667 209 107 209Z" stroke="white" strokeOpacity="0.5" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M86 66L148 107L86 148V66Z" stroke="white" strokeOpacity="0.5" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
      

    </Container>

    <Container
      sx={{
        display: "block",
        position: "absolute",
        bottom: 32,
        left: "50%",
        transform: "translate(-50%, 0)",
        cursor: "pointer",
        "@media(min-width: 1170px)": {
          display: "none",
        }
      }}
      onClick={() => scrollToTarget("sectionTwo")}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="17" viewBox="0 0 32 17" fill="none">
        <path d="M30 2L16 14L2 2" stroke="white" strokeWidth="4" />
      </svg>
    </Container>

  </BannerBase >
};

export default Banner;


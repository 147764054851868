import react, { FC, useEffect, useState } from "react";
import Image from "next/image";
import { regraDeTres } from "../../../utils";

import { Container, Row, Column } from '../../Global/'
import { SectionBase, GridGreen, H2, H3, Paragrafo } from "./style";
import Scroll from "../Scroll";

type BannerProps = {
  children?: React.ReactNode;
  data: {};
};


const imagesGif = [
  '/images/webp/home/gif01.webp',
  '/images/webp/home/gif02.webp',
  '/images/webp/home/gif03.webp',
  '/images/webp/home/gif04.webp',
];

const SectionTwo: FC<BannerProps> = ({ children, data }) => {

  const [imgGif, setImgeGif] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setImgeGif((prevIndex) => (prevIndex + 1) % imagesGif.length);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return <SectionBase>

    <GridGreen />

    <Scroll
      colorText="#000000"
      colorSeta="#3EFF2F"
      idSection="sectionTwo"
      sx={
        {
          position: "absolute",
          bottom: 40,
          right: `${regraDeTres(70, 1920)}%`,
          top: 0,
          zIndex: 3,
          width: 212,
          transform: "translate(0%, 0%) rotate(-90deg)",
          height: "60px",
          cursor: "pointer",
          display: "none",
          "@media(min-width: 1170px)": {
            display: "flex",
          }
        }
      }
    />

    <Row
      id="sectionTwo"
      sx={{
        position: "relative",
        zindex: "2",
        "@media (min-width: 1170px)": {
          marginleft: "22%",
          display: "flex",
          flexDirection: "column",
          width: "100%",
          maxWidth: `${regraDeTres(1388, 1920)}%`,
          marginLeft: `${regraDeTres(400, 1920)}%`,
          ".img4D": {
            width: "100%",
            height: "auto",
          }
        }
      }}
    >
      <Container
        sx={{
          maxWidth: "100%",
          marginTop: "80px",
          padding: "0px 17px",
          "@media (min-width: 1170px)": {
            padding: "0px",
            marginTop: "-90px",
          },
        }}
      >
        <H2>{ data.first_section_title }</H2>
      </Container>

      <Container
        sx={{
          textAlign: "center",
          width: `${regraDeTres(258, 358)}%`,
          margin: "80px auto 0px 0px",
          "@media(min-width: 1170px)": {
            margin: "33px auto 0px auto",
            width: `${regraDeTres(1140, 1388)}%`,
            paddingLeft: `${regraDeTres(150, 1388)}%`,
          }
        }}

      >
        <Image className="img4D" src={`${ data?.first_section_media[0].attributes.url }`} alt="Minha Imagem" width={964} height={625} />
      </Container>
    </Row>

    <Row
      justifyContent="space-between"
      sx={{
        width: "100%",
        position: "relative",
        zIndex: 1,
        marginTop: "-80px",
        "@media(min-width: 1170px)": {
          marginTop: "-450px",
        },
        "@media(min-width: 1600px)": {
          marginTop: "-600px",
        },
        "@media(min-width: 1800px)": {
          marginTop: "-660px",
        }
      }}
    >
      <Container className="gif"
        sx={{
          width: `${regraDeTres(350, 358)}%`,
          marginLeft: `-${regraDeTres(70, 358)}%`,
          position: "relative",
          "@media(min-width: 1170px)": {
            width: `${regraDeTres(864, 1920)}%`,
            marginLeft: `${regraDeTres(263, 1920)}%`,
          }
        }}
      >
        {
          imagesGif.map((item, index) => (
            <Image
              key={`gif${index}`}
              style={{
                position: index == 0 ? "relative" : "absolute",
                opacity: imgGif === index ? "1" : "0",
                display: "block",
                height: "100%",
                width: "100%",
                top: 0,
              }} className="imgGif" src={item} alt="Minha Imagem" width={656} height={954} />
          ))
        }
      </Container>

      <Row
        className="design"
        justifyContent="center"
        alignItems="center"

        sx={
          {
            marginLeft: "-100px",
            "@media(max-width: 1160px)": {
              position: "absolute",
              top: "50%",
              transform: "translate(0%, -40%)",
              zIndex: -1,
              right: "8%",
            },
            "@media(min-width: 1170px)": {
              marginRight: `${regraDeTres(122, 1920)}%`,
              alignSelf: "flex-end",
            }
          }
        }
      >
        <Row
          alignItems="center"
          justifyContent="center"
          sx={{
            "@media(min-width: 1170px)": {
              paddingBottom: 170,
            },
            "@media(min-width: 1600px)": {
              paddingBottom: 200,
            }
          }}
        >
          <Container
            sx={{
              marginRight: "-10px",
              marginTop: "10px",
              alignSelf: "auto",
              "img": {
                width: "70px",
                height: "70px",
              },
              "@media(min-width: 1170px)": {
                width: "120px",
                height: "120px",
              }
            }}
          >
            <Image src="/images/png/iconMore.png" alt="Minha Imagem" width={120} height={120} />
          </Container>


          <Container>
            <H3>Design</H3>
            <H3>Driven</H3>
            <Container sx={{
              marginBottom: "30px",
              "@media(min-width: 1170px)": {
                marginBottom: "60px",
              }
            }}></Container>
            <H3>Data</H3>
            <H3>Driven</H3>
          </Container>

        </Row>
      </Row>
    </Row>

    <Row
      alignItems="flex-end"
      justifyContent="flex-end"
      sx={{
        width: "100%",

      }}
    >
      <Container
        sx={{
          textAlign: "right",
          maxWidth: "100%",
          width: `${regraDeTres(240, 358)}%`,
          marginRight: `${regraDeTres(14, 358)}%`,
          marginBottom: "80px",
          marginTop: "25px",
          "@media(min-width: 1170px)": {
            marginTop: "0px",
            width: `${regraDeTres(825, 1920)}%`,
            marginRight: `${regraDeTres(120, 1920)}%`,
            marginBottom: "75px",
          }
        }}
      >
        <Paragrafo>{ data.first_section_description }</Paragrafo>
      </Container>
    </Row>

  </SectionBase >;
};

export default SectionTwo;
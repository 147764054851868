import Head from 'next/head';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Header, Footer } from '../src/components/Global/'
import { Banner, SectionTwo, News, Costumers, Methodologies, Sliders } from '../src/components/Home';
import { Layout } from '../src/Layout'
import { GetServerSideProps } from 'next';
import { GET_HOME_INFO } from '@/queries/';
import client from '@/apolloClient';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

const Hero = styled.div`
  position: relative;
`

export default function Home(props: any) {
  const [clientWidth, setClientWidth] = useState<number>(1920);
  

  useEffect(() => {
    setClientWidth(document?.body?.clientWidth);
    if (document?.body?.clientWidth < 1170) return () => { };
    
    gsap.registerPlugin(ScrollTrigger);
    const tlOnLoad = gsap.timeline({ paused: true });

    tlOnLoad.fromTo(
      ".fadebar",
      { height: 0 },
      {
        height: "100%",
        duration: 0.5,
        ease: "elastic.inOut(1, 1)",
        delay: 0.1,
      }
    ).fromTo(
      ".fade",
      { opacity: 0 },
      {
        opacity: 1,
        duration: 1,
        ease: "power4",
        delay: 1,
      },
      "-=0.8"
    )
    tlOnLoad.play();



    let ctx = gsap.context(() => {
      let sections = gsap.utils.toArray(".methodology__inner")

      gsap.to(sections, {
        xPercent: -100 * (sections.length - 1),
        ease: "power1.inOut",
        scrollTrigger: {
          trigger: "#methodology",
          pin: true,
          scrub: 1,
          end: () => "+=" + document.querySelector("#methodology").offsetWidth,
        },
      });

      let content: any = gsap.utils.toArray(".floatingContentBlock")
      let arrows: any = gsap.utils.toArray("#methodologyArrows path")
      let arrowColors = [
        "#00FF00",
        "#FF7B00",
        "#00D1FF",
        "#F83FE5"
      ]
      sections.forEach((sec: any, index) => {
        gsap.to(sec, {
          scrollTrigger: {
            trigger: sec,
            start: (window.innerWidth * index + 100) + "px",
            end: (window.innerWidth * index) + (window.innerWidth) + "px",
            onEnter: () => {
              if (!content[index]) return;
              content[index].classList.add('methodologyContentBlockActive')
              arrows[index].classList.add('fullOpacity')
              arrows[index].style.fill = arrowColors[index]
            },
            onLeave: () => {
              if (!content[index]) return;
              if (index === 3) return;
              content[index].classList.remove('methodologyContentBlockActive')
              arrows[index].classList.remove('fullOpacity')
              arrows[index].style.fill = "#646464"
            },
            onEnterBack: () => {
              if (!content[index]) return;
              content[index].classList.add('methodologyContentBlockActive')
              arrows[index].classList.add('fullOpacity')
              arrows[index].style.fill = arrowColors[index]
            },
            onLeaveBack: () => {
              if (!content[index]) return;
              content[index].classList.remove('methodologyContentBlockActive')
              arrows[index].classList.remove('fullOpacity')
              arrows[index].style.fill = "#646464"
            }
          }
        })
      })
    })
    return () => ctx.revert();
  }, [clientWidth])
  
  return (
    <Layout>
      <Head>
        <title>IDKMedia</title>
        <meta name="title" content="IDKMedia" />
        <meta name="description" content="4D aplicado ao branding é entender que o consumidor da sua marca está cada vez mais dinâmico, exigente e informado. É preciso entender os dados, agir rápido e se renovar sempre." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://idkmedia.co/" />
        <meta property="og:title" content="IDKMedia" />
        <meta property="og:description" content="4D aplicado ao branding é entender que o consumidor da sua marca está cada vez mais dinâmico, exigente e informado. É preciso entender os dados, agir rápido e se renovar sempre." />
        <meta property="og:image" content="https://idkmedia.co/" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://idkmedia.co/" />
        <meta property="twitter:title" content="IDKMedia" />
        <meta property="twitter:description" content="4D aplicado ao branding é entender que o consumidor da sua marca está cada vez mais dinâmico, exigente e informado. É preciso entender os dados, agir rápido e se renovar sempre." />
        <meta property="twitter:image" content="https://idkmedia.co/" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Hero>
        <Header type="default" />

        <Banner
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
          data={props.Banner}
        />

        <SectionTwo data={props.first_section} />

        {
          clientWidth <= 1170 ?
            <Sliders data={props.Methodologies} /> :
            <Methodologies data={props.Methodologies} />
        }



        <Costumers data={props.Customers} />

        <News data={props.Posts} />

        <Footer />

      </Hero>

    </Layout>
  )
}


export const getServerSideProps: GetServerSideProps = async () => {
  const data = await client.query({
    query: GET_HOME_INFO
  })

  const dataResult = {
    Banner: {
      Video_and_frames: data.data.homepage.data.attributes.Video_and_frames.data[0].attributes,
      Video_and_frames_mobile: data.data.homepage.data.attributes.Video_and_frames?.data[1]?.attributes ?? null,
      url_video: data.data.homepage.data.attributes.url_video,
    },
    Methodologies: data.data.homepage.data.attributes.Metodologias,
    Customers: data.data.homepage.data.attributes.Customers,
    first_section: {
      first_section_description: data.data.homepage.data.attributes.first_section_description,
      first_section_media: data.data.homepage.data.attributes.first_section_media.data,
      first_section_title: data.data.homepage.data.attributes.first_section_title,
    },
    Posts: data.data.posts.data.slice(0, 4)
  }

  return {
    props: dataResult,
  }
}
import { FC, useEffect, useLayoutEffect, useRef, useState } from "react";
import Image from "next/image";
import { regraDeTres } from "../../../utils";
import { SectionBase, MainWrap, Wrapper, Title, Text, Title2, BackgroundImagesCenter } from './styled';
import { Row, Container } from "../../Global/";
import Circle from '../../../../public/images/svg/Circle'
import FourD from '../../../../public/images/svg/FourD';
import Scroll from "../Scroll";

type BannerProps = {
  children?: React.ReactNode;
  data: {};
};

const Sliders: FC<BannerProps> = (data) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);
  console.log(loading)
  return (
    <SectionBase style={{ userSelect: "none", display: loading ? "none" : "", transition: "opacity 0.5s ease-in-out" }}>

      <>
        <Scroll
          colorText="#FFFFFF"
          colorSeta="#3EFF2F"
          idSection="clients"
          sx={{
            position: "absolute",
            bottom: 80,
            right: 10,
            zIndex: 1,
            width: 212,
            transform: "rotate(-90deg)",
            display: "none",
            cursor: "pointer",
            userSelect: "none",
            "@media(min-width: 1170px)": {
              display: "flex",
            }
          }}
        />

        <Container id="metodology" sx={{ width: "100%", maxWidth: "100%", transform: "translate3d(0,0,0) !important" }}>
          <div className="metodology__inner"></div>
          <Row
            className={'metodology__inner'}
            sx={{
              position: "relative",
              minHeight: "100vh",
              height: "100vh",
              background: "#959595",
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
            }}
          >
            <Container
              sx={{
                position: "absolute",
                top: 0,
                height: "100%",
                left: "50%",
                width: `${regraDeTres(719, 1920)}%`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                "img": {
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }
              }}
            >
              <Image src={`${data.data[0]?.Background.data.attributes.formats.medium.url}`} alt="Minha Imagem" width={622} height={843} />
            </Container>
            <Row
              sx={
                {
                  overflow: "hidden",
                  width: "100%",
                  margin: "335px 0px",
                  gap: "50px",

                  "@media(min-width: 1170px)": {
                    width: "100%",
                    margin: "0px",
                    gap: "0px",
                  }
                }
              }
            >

              <BackgroundImagesCenter>
                <Container
                  sx={{
                    width: `${regraDeTres(328, 358)}%`,
                    height: "100%",
                    position: "relative",
                    maxWidth: "500px",
                    "@media(min-width: 1170px)": {
                      width: `${regraDeTres(964, 1920)}%`,
                    }
                  }}

                >
                  <Container
                    sx={
                      {
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginLeft: "-10px",
                        marginTop: "-20px",
                        width: `${regraDeTres(250, 964)}%`,
                        transform: "translate(-100%, -100%)",


                      }
                    }
                  >
                    <Circle
                      color="#828282"
                      width="100%"
                      height="auto"
                    />
                  </Container>
                  <Container
                    sx={
                      {
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginLeft: "10px",
                        marginTop: "-10px",
                        width: `${regraDeTres(250, 964)}%`,
                        transform: "translate(0%, -100%)",

                      }
                    }
                  >
                    <Circle
                      color="#828282"
                      width="100%"
                      height="auto"
                      rotate={90}
                    />
                  </Container>
                  <Container
                    sx={
                      {
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginleft: "20px",
                        marginTop: "10px",
                        width: `${regraDeTres(250, 964)}%`,
                        transform: "translate(0%, 0%)",

                      }
                    }
                  >
                    <Circle
                      color="#828282"
                      width="100%"
                      height="auto"
                      rotate={180}
                    />
                  </Container>
                  <Container
                    sx={
                      {
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginLeft: "-20px",
                        marginTop: "0px",
                        width: `${regraDeTres(250, 964)}%`,
                        transform: "translate(-100%, 0%)",

                      }
                    }
                  >
                    <Circle
                      color="#00C900"
                      width="100%"
                      height="auto"
                      rotate={270}

                    />
                  </Container>
                  <Row
                    alignItems="center"
                    sx={
                      {
                        height: "100%",
                      }
                    }
                  >
                    <Image src={`${data.data[0].Media.data.attributes.url}`} alt="Minha Imagem" width={964} height={843} />
                  </Row>
                </Container>
              </BackgroundImagesCenter>
            </Row>
            <MainWrap
              sx={{
                "@media (max-width: 768px)": {
                  marginTop: -900
                }
              }}
            >
              <Wrapper>
                <Title>{data.data[0].Title}</Title>
                <Text
                  $afterColor="#00C900"
                > {data.data[0].Description} </Text>
              </Wrapper>
            </MainWrap>
          </Row>

          <Row
            className={'metodology__inner'}
            alignItems="flex-start"
            justifyContent="flex-start"
            sx={{
              position: "relative",
              overflow: "hidden",
              minHeight: "100vh",
              background: "#959595",
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              "@media(max-width: 1170px)": {
                justifyContent: "flex-end",
              }
            }}
          >
            <Container
              sx={{
                position: "absolute",
                top: 0,
                height: "100%",
                left: "50%",
                width: `${regraDeTres(719, 1920)}%`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                "img": {
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }
              }}
            >
              <Image src={`${data.data[1]?.Background.data.attributes.formats.medium.url}`} alt="Minha Imagem" width={622} height={843} />
            </Container>

            <Row
              align="space-beetween"
              sx={{
                flexDirection: "column-reverse",
                marginTop: "60px",
                height: "100%",
                "@media(max-width: 1170px)": {
                  gap: 50,
                }
              }}
            >
              <BackgroundImagesCenter>
                <Container
                  sx={{
                    width: `${regraDeTres(328, 358)}%`,
                    height: "100%",
                    position: "relative",
                    maxWidth: "500px",
                    "@media(min-width: 1170px)": {
                      width: `${regraDeTres(827, 1920)}%`,
                    }
                  }}

                >
                  <Container
                    sx={
                      {
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginLeft: "-10px",
                        marginTop: "-20px",
                        width: `${regraDeTres(250, 827)}%`,
                        transform: "translate(-100%, -100%)",
                        zIndex: -1,


                      }
                    }
                  >
                    <Circle
                      color="#FF7B00"
                      width="100%"
                      height="auto"
                    />
                  </Container>

                  <Container
                    sx={
                      {
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginLeft: "10px",
                        marginTop: "-10px",
                        width: `${regraDeTres(250, 827)}%`,
                        transform: "translate(0%, -100%)",
                        zIndex: -1,

                      }
                    }
                  >
                    <Circle
                      color="#828282"
                      width="100%"
                      height="auto"
                      rotate={90}
                    />
                  </Container>

                  <Container
                    sx={
                      {
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginleft: "20px",
                        marginTop: "10px",
                        width: `${regraDeTres(250, 827)}%`,
                        transform: "translate(0%, 0%)",
                        zIndex: -1,

                      }
                    }
                  >
                    <Circle
                      color="#828282"
                      width="100%"
                      height="auto"
                      rotate={180}
                    />
                  </Container>

                  <Container
                    sx={
                      {
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginLeft: "-20px",
                        marginTop: "0px",
                        width: `${regraDeTres(250, 827)}%`,
                        transform: "translate(-100%, 0%)",
                        zIndex: -1,

                      }
                    }
                  >
                    <Circle
                      color="#828282"
                      width="100%"
                      height="auto"
                      rotate={270}

                    />
                  </Container>

                  <Row
                    justifyContent="flex-end"
                    alignItems="flex-end"
                    sx={
                      {
                        height: "100%",
                      }
                    }
                  >
                    <Container
                      sx={{
                        width: `${regraDeTres(676, 827)}%`,
                        margin: "0 auto",
                      }}
                    >
                      <Image src={`${data.data[1].Media.data.attributes.url}`} alt="Minha Imagem" width={676} height={705} />
                    </Container>

                  </Row>
                </Container>
              </BackgroundImagesCenter>

              <MainWrap>
                <Wrapper>
                  <Title>{data.data[1].Title}</Title>
                  <Text
                    $afterColor="#FF7B00"
                  > {data.data[1].Description} </Text>
                </Wrapper>
              </MainWrap>
            </Row>
          </Row>

          <Row
            className={'metodology__inner'}
            justifyContent="flex-end"
            sx={{
              overflow: "hidden",
              position: "relative",
              minHeight: "100vh",
              background: "#959595",
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              "@media(min-width: 1170px)": {
                justifyContent: "start"
              }
            }}
          >

            <Container
              sx={{
                position: "absolute",
                top: 0,
                height: "100%",
                right: "50%",
                width: `${regraDeTres(719, 1920)}%`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                "img": {
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }
              }}
            >
              <Image src={`${data.data[2]?.Background.data.attributes.formats.medium.url}`} alt="Minha Imagem" width={622} height={843} />
            </Container>

            <Row
              sx={{
                flexDirection: "column-reverse",
                gap: "50px",
                marginTop: "50px",
                "@media(min-width: 1170px)": {
                  gap: "0px",
                  marginTop: "0px",
                }
              }}
            >

              <BackgroundImagesCenter>
                <Container
                  sx={{
                    width: `${regraDeTres(328, 358)}%`,
                    height: "100%",
                    position: "relative",
                    maxWidth: "500px",
                    "@media(min-width: 1170px)": {
                      width: `${regraDeTres(863, 1920)}%`,
                    }
                  }}

                >
                  <Container
                    sx={
                      {
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginLeft: "-10px",
                        marginTop: "-20px",
                        width: `${regraDeTres(250, 863)}%`,
                        transform: "translate(-100%, -100%)",
                        zIndex: -1,

                      }
                    }
                  >
                    <Circle
                      color="#828282"
                      width="100%"
                      height="auto"
                    />
                  </Container>

                  <Container
                    sx={
                      {
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginLeft: "10px",
                        marginTop: "-10px",
                        width: `${regraDeTres(250, 863)}%`,
                        transform: "translate(0%, -100%)",
                        zIndex: -1,
                      }
                    }
                  >
                    <Circle
                      color="#00B3DB"

                      width="100%"
                      height="auto"
                      rotate={90}
                    />
                  </Container>

                  <Container
                    sx={
                      {
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginleft: "20px",
                        marginTop: "10px",
                        width: `${regraDeTres(250, 863)}%`,
                        transform: "translate(0%, 0%)",
                        zIndex: -1,
                      }
                    }
                  >
                    <Circle
                      color="#828282"
                      width="100%"
                      height="auto"
                      rotate={180}
                    />
                  </Container>

                  <Container
                    sx={
                      {
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginLeft: "-20px",
                        marginTop: "0px",
                        width: `${regraDeTres(250, 863)}%`,
                        transform: "translate(-100%, 0%)",
                        zIndex: -1,
                      }
                    }
                  >
                    <Circle
                      color="#828282"
                      width="100%"
                      height="auto"
                      rotate={270}

                    />
                  </Container>

                  <Row
                    alignItems="flex-end"
                    sx={
                      {
                        height: "100%",
                      }
                    }
                  >
                    <Container
                      sx={{
                        width: `${regraDeTres(700, 863)}%`,
                        margin: "0 auto",
                      }}
                    >
                      <Image src={`${data.data[2].Media.data.attributes.url}`} alt="Minha Imagem" width={700} height={843} />
                    </Container>
                  </Row>
                </Container>

              </BackgroundImagesCenter>

              <MainWrap>

                <Row
                  justifyContent="flex-end"
                  sx={{
                    width: "100%",
                  }}
                >
                  <Wrapper
                    sx={{
                      alignSelf: "flex-end",
                      justifySelf: "flex-end",
                    }}
                  >
                    <Title>Imaginar</Title>
                    <Text
                      $afterColor="#00B3DB"
                    >Com o problema mapeado, partimos para a co-criação — com clientes
                      e usuários — de possíveis soluções. Buscamos caminhos diferentes
                      e prototipamos as melhores ideias através de testes.</Text>
                  </Wrapper>
                </Row>


              </MainWrap>
            </Row>
          </Row>

          <Row
            className={'metodology__inner'}
            justifyContent="flex-end"
            sx={{
              position: "relative",
              overflow: "hidden",
              minHeight: "100vh",
              background: "#959595",
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              "@media(min-width: 1170px)": {
                justifyContent: "start"
              }
            }}
          >

            <Container
              sx={{
                position: "absolute",
                top: 0,
                height: "100%",
                right: "50%",
                width: `${regraDeTres(719, 1920)}%`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                "img": {
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }
              }}
            >
              <Image src={`${data.data[3]?.Background.data.attributes.formats.medium.url}`} alt="Minha Imagem" width={622} height={843} />
            </Container>

            <Row
              sx={{
                flexDirection: "column-reverse",
                gap: "50px",
                marginTop: "50px",
                "@media(min-width: 1170px)": {
                  gap: "0px",
                  marginTop: "0px",
                }
              }}
            >

              <BackgroundImagesCenter>
                <Container
                  sx={{
                    width: `${regraDeTres(328, 358)}%`,
                    height: "100%",
                    position: "relative",
                    maxWidth: "500px",
                    "@media(min-width: 1170px)": {
                      width: `${regraDeTres(809, 1920)}%`,
                    }
                  }}

                >
                  <Container
                    sx={
                      {
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginLeft: "-10px",
                        marginTop: "-20px",
                        width: `${regraDeTres(250, 809)}%`,
                        transform: "translate(-100%, -100%)",
                        zIndex: -1,


                      }
                    }
                  >
                    <Circle
                      color="#828282"
                      width="100%"
                      height="auto"
                    />
                  </Container>

                  <Container
                    sx={
                      {
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginLeft: "10px",
                        marginTop: "-10px",
                        width: `${regraDeTres(250, 809)}%`,
                        transform: "translate(0%, -100%)",
                        zIndex: -1,

                      }
                    }
                  >
                    <Circle
                      color="#828282"
                      width="100%"
                      height="auto"
                      rotate={90}
                    />
                  </Container>

                  <Container
                    sx={
                      {
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginleft: "20px",
                        marginTop: "10px",
                        width: `${regraDeTres(250, 809)}%`,
                        transform: "translate(0%, 0%)",

                      }
                    }
                  >
                    <Circle
                      color="#F83FE5"
                      width="100%"
                      height="auto"
                      rotate={180}
                    />
                  </Container>

                  <Container
                    sx={
                      {
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginLeft: "-20px",
                        marginTop: "0px",
                        width: `${regraDeTres(250, 809)}%`,
                        transform: "translate(-100%, 0%)",
                        zIndex: -1,

                      }
                    }
                  >
                    <Circle
                      color="#828282"
                      width="100%"
                      height="auto"
                      rotate={270}

                    />
                  </Container>

                  <Row
                    alignItems="flex-end"
                    sx={
                      {
                        height: "100%",
                      }
                    }
                  >
                    <Container
                      sx={{
                        width: `${regraDeTres(622, 809)}%`,
                        margin: "0 auto",
                      }}
                    >
                      <Image src={`${data.data[3].Media.data.attributes.url}`} alt="Minha Imagem" width={622} height={843} />
                    </Container>
                  </Row>

                </Container>

              </BackgroundImagesCenter>

              <MainWrap>
                <Row
                  justifyContent="flex-end"
                  alignItems="center"
                  sx={{
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <Wrapper
                  >
                    <Title>{data.data[3].Title}</Title>
                    <Text
                      $afterColor="#F83FE5"
                    >{data.data[3].Description} </Text>
                  </Wrapper>
                </Row>
              </MainWrap>

            </Row>
          </Row>

          <Row
            className={'metodology__inner'}
            sx={{
              position: "relative",
              minHeight: "100vh",
              overflow: "hidden",
              background: "#959595",
              // maxHeight: "100vh",
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
            }}
          >

            <Row
              alignItems="center"
              justifyContent="center"
              sx={{
                position: "absolute",
                zIndex: 0,
                height: "100%",
                width: "100%",
                'svg': {
                  height: "100%",
                }
              }}
            >
              <FourD color="#9D9D9D" />
            </Row>

            <BackgroundImagesCenter>
              <Container
                sx={{
                  width: `${regraDeTres(328, 358)}%`,
                  height: "100%",
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  minHeight: "65vw",
                  margin: "50px 0px",
                  "@media(min-width: 1170px)": {
                    margin: "0px",
                    width: `${regraDeTres(609, 1920)}%`,
                    minHeight: "100%",
                  }
                }}

              >
                <Title2>
                  Design Driven <br />
                  Data Driven
                </Title2>

                <Container
                  sx={
                    {
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginLeft: "-10px",
                      marginTop: "-20px",
                      width: `${regraDeTres(180, 609)}%`,
                      transform: "translate(-100%, -100%)",
                      "@media(min-width: 1170px)": {
                        width: `${regraDeTres(250, 609)}%`,
                      }
                    }
                  }
                >
                  <Circle
                    color="#FF7B00"
                    width="100%"
                    height="auto"
                  />
                </Container>

                <Container
                  sx={
                    {
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginLeft: "10px",
                      marginTop: "-10px",
                      width: `${regraDeTres(180, 609)}%`,
                      transform: "translate(0%, -100%)",
                      "@media(min-width: 1170px)": {
                        width: `${regraDeTres(250, 609)}%`,
                      }

                    }
                  }
                >
                  <Circle
                    color="#00D1FF"
                    width="100%"
                    height="auto"
                    rotate={90}
                  />
                </Container>

                <Container
                  sx={
                    {
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginleft: "20px",
                      marginTop: "10px",
                      width: `${regraDeTres(180, 609)}%`,
                      transform: "translate(0%, 0%)",
                      "@media(min-width: 1170px)": {
                        width: `${regraDeTres(250, 609)}%`,
                      }

                    }
                  }
                >
                  <Circle
                    color="#F83FE5"
                    width="100%"
                    height="auto"
                    rotate={180}
                  />
                </Container>

                <Container
                  sx={
                    {
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginLeft: "-20px",
                      marginTop: "0px",
                      width: `${regraDeTres(180, 609)}%`,
                      transform: "translate(-100%, 0%)",
                      "@media(min-width: 1170px)": {
                        width: `${regraDeTres(250, 609)}%`,
                      }

                    }
                  }
                >
                  <Circle
                    color="#00FF00"
                    width="100%"
                    height="auto"
                    rotate={270}

                  />
                </Container>
              </Container>

            </BackgroundImagesCenter>


            <MainWrap>
              <Row
                justifyContent="space-between"
                sx={{
                  marginBottom: 50,
                  width: "100%",
                  gap: "50px",
                  "@media(min-width: 1170px)": {
                    gap: "0px",
                  }
                }}
              >


                <Wrapper>
                  <Title>Investigar</Title>
                  <Text
                    $afterColor="#FF7B00"
                  > Nesta fase, analisamos o problema apresentado pelo ponto de vista das pessoas que o vivenciam. Esse trabalho de investigação foca nas pesquisas, dados e conversas com as pessoas. O aprendizado obtido ajuda a identificar o real problema a ser atacado.</Text>
                </Wrapper>

                <Wrapper
                >
                  <Title>Imaginar</Title>
                  <Text
                    $afterColor="#00B3DB"
                  >Com o problema mapeado, partimos para a co-criação — com clientes
                    e usuários — de possíveis soluções. Buscamos caminhos diferentes
                    e prototipamos as melhores ideias através de testes.</Text>
                </Wrapper>
              </Row>
              <Row
                justifyContent="space-between"
                sx={{
                  width: "100%",
                  gap: "50px",
                  marginBottom: 50,
                  "@media(min-width: 1170px)": {
                    gap: "0px",
                  }
                }}
              >

                <Wrapper >
                  <Title>Mensurar</Title>
                  <Text
                    $afterColor="#00C900"
                  >O lançamento de uma solução
                    no mercado é apenas o ponto
                    de partida. Para sabermos
                    o verdadeiro impacto do trabalho
                    é preciso analisar métricas,
                    que poderão nos guiar para
                    as próximas etapas.</Text>
                </Wrapper>

                <Wrapper>
                  <Title>Implementar</Title>
                  <Text
                    $afterColor="#F83FE5"
                  >Após a criação e testes das soluções, o time de design avança no refinamento das soluções, que passam a ser construídas pelo time de desenvolvimento. É a última etapa antes da solução ser lançada no mercado.</Text>
                </Wrapper>
              </Row>
            </MainWrap>

          </Row>
        </Container>
      </>

    </SectionBase >
  )
};

export default Sliders;
import React from "react";

type IconProps = {
    color: string;
    sx?: Partial<React.CSSProperties>;
};

function Seta({ color, sx }: IconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="196" height="49" viewBox="0 0 196 49" fill="none" style={sx}>
            <path fillRule="evenodd" clipRule="evenodd" d="M14.2034 28.146L26.7525 43.3481L20.5641 48.146L0.752453 24.146L20.5641 0.145996L26.7525 4.94389L14.2034 20.146L195.752 20.146V28.146L14.2034 28.146Z" fill={color} />
        </svg>
    );
}

export default Seta;
